import React, { useEffect, useState } from "react";
import Loader from "../common/Loader";
import APIservices from "../../services/APIservices";
import { Button, Card, CardDeck, Col, Row } from "react-bootstrap";
import AddUserModel from "./AddUserModel";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import {
  WhatsappIcon,
  WhatsappShareButton,
  EmailShareButton,
  EmailIcon,
} from "react-share";

export default function EntityView(props) {
  const [state, setState] = useState({
    isLoading: true,
    data: {},
  });

  let alterState = (key, value) => {
    let st = state;
    if (typeof key === "object") {
      st = { ...state, ...key };
    } else if (
      value === null ||
      value === undefined ||
      typeof value === "object"
    ) {
      st[key] = value;
    } else {
      st[key] = value.constructor === String ? value.trim() : value;
    }
    setState({ ...st });
  };

  useEffect(() => {
    APIservices.getEntity(props.match.params.uuid).then((response) => {
      // console.log(response.data);
      if (response.data.status === true) {
        alterState({ data: response.data.data, isLoading: false });
      } else {
        alert(
          "Error : " +
            response.data.data.message +
            " (" +
            response.data.data.code +
            ")"
        );
        // console.log(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isLoading) {
    return <Loader />;
  } else {
    return (
      <div className="view-entity-details">
        <div className="h1 entity-margin-1">
          Entity &nbsp;
          <small>{state.data.name}</small>
        </div>
        <EntityInfo data={state.data} />
        <RelatedDocument documents={state.data.documents} />
      </div>
    );
  }
}
let EntityInfo = function (props) {
  return (
    <Row>
      <Col md={8}>
        <img className="img-fluid" src={props.data.image.url} alt="" />
      </Col>
      <Col md={4}>
        <div className="h3 entity-margin-2">{props.data.name}</div>
        <p></p>
        <DocumentsAttached documents={props.data.documents} />
        <NotificationInfo data={props.data} />
      </Col>
    </Row>
  );
};

let NotificationInfo = function (props) {
  const [state, setState] = useState({
    showAddUserModel: false,
  });

  let alterState = (key, value) => {
    let st = state;
    if (typeof key === "object") {
      st = { ...state, ...key };
    } else if (
      value === null ||
      value === undefined ||
      typeof value === "object"
    ) {
      st[key] = value;
    } else {
      st[key] = value.constructor === String ? value.trim() : value;
    }
    setState({ ...st });
  };

  return (
    <div>
      <AddUserModel
        entityId={props.data.uuid}
        alterState={alterState}
        show={state.showAddUserModel}
      />
      <div className="h3 entity-margin-2">
        Notifications &nbsp;
        <small>
          <Button
            onClick={() => {
              alterState("showAddUserModel", true);
            }}
            variant="primary"
            size="sm"
          >
            Add User
          </Button>
        </small>
      </div>
      <ul>
        {props.data.user_notifications.map((user_notification, subIndex) => {
          return (
            <li key={user_notification.uuid}>
              <div className="font-weight-bold">
                <span>
                  {user_notification.name} (
                  {user_notification.notification_type} :{" "}
                  {user_notification.notification_type_value}){" "}
                </span>
              </div>
              <div>
                <span className="font-italic">Notify Before:</span>
                <span className="font-weight-light">
                  {user_notification.durations.map((duration, subIndex) => {
                    return duration.name + ",";
                  })}
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

let DocumentsAttached = function (props) {
  return (
    <div>
      <div className="h3 entity-margin-2">Documents Attached</div>
      <ul>
        {props.documents.map((document, subIndex) => {
          return <li key={document.uuid}> {document.name} </li>;
        })}
      </ul>
    </div>
  );
};

let RelatedDocument = function (props) {
  return (
    <div>
      <div className="h4 entity-margin-1">Related Documents</div>

      <Row className="card-align">
        {props.documents.map((document, subIndex) => {
          return (
            <CardDeck>
              <DocumentCard key={document.uuid} document={document} />
            </CardDeck>
          );
        })}
        ;
      </Row>
    </div>
  );
};

let DocumentCard = function (props) {
  return (
    <Card border="light" style={{ width: "18rem" }}>
      <Card.Header>{props.document.type.name}</Card.Header>
      <Card.Body>
        <Card.Title>
          <Link to={"/document/" + props.document.uuid}>
            {props.document.name}
          </Link>
        </Card.Title>
        <Card.Text>
          <p>
            Provider : {props.document.provider.name} <br />
            Validity : {dateFormat(
              props.document.valid_from,
              "dd-mm-yyyy"
            )} -{" "}
            {props.document.is_lifetime
              ? "Lifetime"
              : dateFormat(props.document.valid_to, "dd-mm-yyyy")}
            <br />
            <a
              rel="noopener noreferrer"
              className="button"
              href={props.document.file.file_url}
              target="_blank"
            >
              Download
            </a>
            &nbsp; &nbsp;
            <a
              rel="noopener noreferrer"
              className="button"
              href={props.document.file.file_view_url}
              target="_blank"
            >
              View
            </a>
          </p>
          <p>
            <WhatsappShareButton
              title={props.document.name}
              url={props.document.file.file_url}
            >
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>

            <EmailShareButton
              title={props.document.name}
              url={props.document.file.file_url}
            >
              <EmailIcon size={32} round={true} />
            </EmailShareButton>
          </p>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
