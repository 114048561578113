import React from "react";

export default function Loader(props) {
  return (
    <div className="d-flex justify-content-center">
      <div
        className="spinner-grow text-light"
        style={{ width: "30rem", height: "30rem" }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}
