import React, { useEffect, useState } from "react";
import APIservices from "../../services/APIservices";
import AlertPopup from "../common/AlertPopup";
import { Button, Form, Modal } from "react-bootstrap";
import Loader from "../common/Loader";

export default function AddUserModel(props) {
  const [state, setState] = useState({
    formData: {
      entity_id: props.entityId,
      name: "",
      notification_type: "",
      notification_type_value: "",
      durations: [],
    },
    notification_types: [],
    durations: [],
    isLoading: false,
    showAlert: {
      show: false,
      isError: false,
      message: "",
    },
  });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let close = (e) => {
    closeModel();
  };

  let closeModel = () => {
    alterState({
      isLoading: false,
      formData: {
        entity_id: props.entityId,
        name: "",
        notification_type: "",
        notification_type_value: "",
        durations: [],
      },
      showAlert: {
        show: false,
        isError: false,
        message: "",
      },
    });
    props.alterState("showAddUserModel", false);
  };

  let alterAlertState = (key, value) => {
    let showAlert = state.showAlert;
    showAlert[key] = value;
    alterState("showAlert", showAlert);
  };

  let alterState = (key, value) => {
    let st = state;
    if (typeof key === "object") {
      st = { ...state, ...key };
    } else if (
      value === null ||
      value === undefined ||
      typeof value === "object"
    ) {
      st[key] = value;
    } else {
      st[key] = value.constructor === String ? value.trim() : value;
    }
    setState({ ...st });
  };

  let handleUserInput = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = state.formData;
    formData[name] = value;
    alterState("formData", formData);
  };

  let handleSelectMultiUserInput = (e) => {
    e.preventDefault();
    const { name } = e.target;
    let formData = state.formData;
    formData[name] = Array.from(e.target.selectedOptions, (item) => item.value);
    alterState("formData", formData);
  };

  let submitForm = async (e) => {
    alterState("isLoading", true);
    e.preventDefault();
    e.stopPropagation();
    APIservices.submitNotificationUser(state.formData).then((response) => {
      let showAlert = state.showAlert;
      showAlert["show"] = response.data.status;
      if (response.data.status === true) {
        showAlert["isError"] = false;
      } else {
        showAlert["isError"] = true;
        showAlert["message"] =
          response.data.data.message + " (" + response.data.data.code + ")";
      }
      alterState({ showAlert: showAlert, isLoading: false });
    });
  };

  let initData = (e) => {
    alterState("isLoading", true);
    const promiseArray = [
      APIservices.getNotificationTypes(),
      APIservices.getDurations(),
    ];
    Promise.allSettled(promiseArray)
      .then((data) => {
        const notificationTypes = data[0].value;
        const durations = data[1].value;
        alterState({
          notification_types:
            notificationTypes.data.status === true
              ? notificationTypes.data.data
              : [],
          durations: durations.data.status === true ? durations.data.data : [],
          isLoading: false,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let getSelectedNotificationTypeName = (uuid) => {
    for (let obj of state.notification_types) {
      if (obj.uuid === uuid) {
        return obj.name;
      }
    }
    return "";
  };

  return (
    <div>
      <AlertPopup
        onConfirm={close}
        alterAlertState={alterAlertState}
        show={state.showAlert.show}
        isError={state.showAlert.isError}
        message={state.showAlert.message}
      />
      <Modal
        onShow={initData}
        size="lg"
        show={props.show}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Add User Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {state.isLoading ? (
            <Loader />
          ) : (
            <div>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Name"
                  value={state.formData.name}
                  onChange={handleUserInput}
                />
              </Form.Group>

              <Form.Group controlId="notification_type">
                <Form.Label>Notification Type</Form.Label>
                <Form.Control
                  name="notification_type"
                  as="select"
                  value={
                    state.formData.notification_type.length === 0
                      ? "Select Notification Type"
                      : state.formData.notification_type
                  }
                  onChange={handleUserInput}
                >
                  <option>Select</option>
                  {state.notification_types.map((notification_type) => {
                    return (
                      <option
                        key={notification_type.uuid}
                        value={notification_type.uuid}
                      >
                        {notification_type.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>

              {state.formData.notification_type.length !== 0 ? (
                <Form.Group controlId="notification_type_value">
                  <Form.Label className="text-capitalize">
                    {getSelectedNotificationTypeName(
                      state.formData.notification_type
                    )}
                  </Form.Label>
                  <Form.Control
                    name="notification_type_value"
                    type="text"
                    placeholder="Value"
                    value={state.formData.notification_type_value}
                    onChange={handleUserInput}
                  />
                </Form.Group>
              ) : (
                <div />
              )}

              <Form.Group controlId="durations">
                <Form.Label>Notify Before</Form.Label>
                <Form.Control
                  multiple={true}
                  name="durations"
                  as="select"
                  value={state.formData.durations}
                  onChange={handleSelectMultiUserInput}
                >
                  {state.durations.map((duration) => {
                    return (
                      <option key={duration.uuid} value={duration.uuid}>
                        {duration.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
          <Button onClick={submitForm} variant="primary">
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
