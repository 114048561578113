import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardDeck,
  Col,
  FormControl,
  Row,
  Form,
} from "react-bootstrap";
import AddEntityModel from "./AddEntityModel";
import AddDocumentModel from "./AddDocumentModel";
import APIservices from "../../services/APIservices";
import Loader from "../common/Loader";
import { useHistory } from "react-router";

export default function Home(props) {
  const [state, setState] = useState({
    formData: { name: "" },
    isLoading: true,
    showAddEntityModel: false,
    showAddDocumentModel: false,
    entityDocuments: [],
    refreshEntityDocuments: false,
  });

  useEffect(() => {
    APIservices.getEntityDocuments(state.formData.name).then((response) => {
      // console.log(response.data);
      if (response.data.status === true) {
        alterState({ entityDocuments: response.data.data, isLoading: false });
      } else {
        alert(
          "Error : " +
            response.data.data.message +
            " (" +
            response.data.data.code +
            ")"
        );
        // console.log(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let alterState = (key, value) => {
    let st = state;
    if (typeof key === "object") {
      st = { ...state, ...key };
    } else if (
      value === null ||
      value === undefined ||
      typeof value === "object"
    ) {
      st[key] = value;
    } else {
      st[key] = value.constructor === String ? value.trim() : value;
    }
    setState({ ...st });
  };
  let refreshEntityDocuments = () => {
    APIservices.getEntityDocuments(state.formData.name).then((response) => {
      // console.log(response.data);
      if (response.data.status === true) {
        alterState({
          entityDocuments: response.data.data,
          refreshEntityDocuments: false,
        });
      } else {
        alert(
          "Error : " +
            response.data.data.message +
            " (" +
            response.data.data.code +
            ")"
        );
        alterState({ refreshEntityDocuments: false });
        // console.log(response.data);
      }
    });
  };

  if (state.refreshEntityDocuments) {
    refreshEntityDocuments();
  }

  let handleUserInput = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = state.formData;
    formData[name] = value;
    alterState("formData", formData);
    // console.log(state.formData);
  };

  if (state.isLoading) {
    return <Loader />;
  } else {
    const chunk = (arr, size) =>
      Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size)
      );
    return (
      <div>
        <AddEntityModel
          alterState={alterState}
          show={state.showAddEntityModel}
        />
        <AddDocumentModel
          alterState={alterState}
          show={state.showAddDocumentModel}
        />
        <Row>
          <Col>
            <div className="pull-right">
              <Row style={{ paddingBottom: "30px" }}>
                <Col>
                  <Button
                    onClick={() => {
                      alterState("showAddEntityModel", true);
                    }}
                    style={{ margin: "5px" }}
                    variant="primary"
                  >
                    Add Entity
                  </Button>
                  <Button
                    onClick={() => {
                      alterState("showAddDocumentModel", true);
                    }}
                    style={{ margin: "5px" }}
                    variant="primary"
                  >
                    Add Document
                  </Button>
                  <Form
                    onClick={() => {
                      refreshEntityDocuments();
                    }}
                    inline
                    className="float-left"
                  >
                    <FormControl
                      name="name"
                      type="text"
                      placeholder="Search"
                      className="mr-sm-2"
                      value={state.formData.name}
                      onChange={handleUserInput}
                    />
                    <Button variant="outline-success">Search</Button>
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        {chunk(state.entityDocuments, 3).map((entityDocuments, index) => {
          return (
            <Row key={index}>
              {entityDocuments.map((entityDocument, subIndex) => {
                return (
                  <CardDeck key={index + subIndex}>
                    <EntityDocumentCard entity={entityDocument} />
                  </CardDeck>
                );
              })}{" "}
            </Row>
          );
        })}
      </div>
    );
  }
}

let EntityDocumentCard = function (props) {
  const history = useHistory();

  let viewEntity = (event, uuid) => {
    let url = "/entity/" + uuid;
    history.push(url);
  };

  return (
    <Col
      onClick={(event) => viewEntity(event, props.entity.uuid)}
      xs={6}
      md={4}
    >
      <Card style={{ width: "18rem" }}>
        <div className="card-img-block">
          <Card.Img variant="top" src={props.entity.image.thumbnail} />
        </div>
        <Card.Body className="pt-0">
          <Card.Title>{props.entity.name}</Card.Title>
          <Card.Text>
            {props.entity.type.name}
            {/*<span>Documents Count : {props.documents}</span>*/}
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};
