import React, { useEffect, useState } from "react";
import APIservices from "../../services/APIservices";
import Loader from "../common/Loader";
import TableData from "../common/TableData";
import { useHistory } from "react-router";

export default function Entity(props) {
  const history = useHistory();

  let viewFile = async (row, operation) => {
    let url =
      typeof operation.link === "function"
        ? operation.link(row)
        : row[operation.link];
    window.open(url, "_blank");
  };

  let viewEntity = (row, operation) => {
    let url = "/entity/" + row.uuid;
    history.push(url);
  };

  let editEntity = (row, operation) => {
    let url = "/entity/" + row.uuid + "/edit";
    history.push(url);
  };

  const [state, setState] = useState({
    isLoading: true,
    filters: {
      page: {
        size: 10,
        current: 1,
      },
    },
    tableData: [],
    columns: {
      fields: [
        {
          dataField: "uuid",
          text: "UUID",
          hidden: true,
        },
        {
          dataField: "name",
          text: "Name",
        },
        {
          dataField: function (row) {
            return row.type.name;
          },
          text: "Entity Type",
        },
      ],
      operation: [
        {
          icon: "fa-download",
          text: "Image",
          link: (row) => {
            return row.image.url;
          },
          onClick: viewFile,
        },
        {
          icon: "fa-eye",
          text: "View",
          onClick: viewEntity,
        },
        {
          icon: "fa-pencil",
          text: "Edit",
          onClick: editEntity,
        },
      ],
    },
  });

  let alterState = (key, value) => {
    let st = state;
    if (typeof key === "object") {
      st = { ...state, ...key };
    } else if (
      value === null ||
      value === undefined ||
      typeof value === "object"
    ) {
      st[key] = value;
    } else {
      st[key] = value.constructor === String ? value.trim() : value;
    }
    setState({ ...st });
  };

  useEffect(() => {
    APIservices.getEntities(state.filters).then((response) => {
      if (response.data.data.error === undefined) {
        alterState({
          tableData: response.data.data,
          isLoading: false,
        });
      } else {
        // console.log(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isLoading) {
    return <Loader />;
  } else {
    return (
      <div>
        <h1>Entity</h1>
        <TableData headers={state.columns} rows={state.tableData} />
      </div>
    );
  }
}
