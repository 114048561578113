import React from "react";
import { Table } from "react-bootstrap";

export default function TableData(props) {
  return (
    <div>
      <Table bordered hover responsive="sm" size="sm">
        <TableHeader headers={props.headers} />
        <TableBody headers={props.headers} rows={props.rows} />
      </Table>
    </div>
  );
}

const TableHeader = (props) => {
  return (
    <thead className="thead-dark" key="header-1">
      <tr key="header-0">
        {props.headers &&
          props.headers.fields &&
          props.headers.fields.map((value, index) => {
            if (!(value.hasOwnProperty("hidden") && value.hidden)) {
              return (
                <th key={index}>
                  <div>{value.text}</div>
                </th>
              );
            }
            return undefined;
          })}
        {props.headers && props.headers.operation && (
          <th
            colSpan={props.headers.operation.length}
            key={
              props.headers &&
              props.headers.fields &&
              props.headers.fields.length + 1
            }
          >
            <div>Actions</div>
          </th>
        )}
      </tr>
    </thead>
  );
};

const TableBody = (props) => {
  function buildRow(row, headers) {
    return (
      <tr key={row.uuid}>
        {headers.fields &&
          headers.fields.map((value, index) => {
            if (!(value.hasOwnProperty("hidden") && value.hidden)) {
              return (
                <td key={index}>
                  {typeof value.dataField === "function"
                    ? value.dataField(row)
                    : row[value.dataField]}
                </td>
              );
            }
            return undefined;
          })}
        {headers.operation && (
          <td
            colSpan={headers.operation.length}
            key={headers.fields.length + Math.floor(Math.random() * 1000)}
          >
            {headers.operation.map((value, index) => {
              let show = true;

              if (value.hasOwnProperty("hidden")) {
                show =
                  typeof value.hidden === "function"
                    ? value.hidden(row)
                    : value.hidden;
              } else {
                show = true;
              }

              if (show) {
                return (
                  <span key={index}>
                    <button
                      onClick={() => {
                        value.onClick && value.onClick(row, value);
                      }}
                      className="btn btn-sm"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <i aria-hidden="true" className={"fa " + value.icon}></i>{" "}
                      {value.text}
                    </button>
                  </span>
                );
              }
              return undefined;
            })}
          </td>
        )}
      </tr>
    );
  }

  return (
    <tbody>
      {props.rows &&
        props.rows.map((value) => {
          return buildRow(value, props.headers);
        })}
    </tbody>
  );
};
