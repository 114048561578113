import React, { useEffect } from "react";
import "./App.css";
import SideBar from "./components/common/SideBar";
import TopNavigation from "./components/common/TopNavigation";
import { PrivateRoute, PublicRoute } from "./services/Route";
import { Switch } from "react-router-dom";
import Login from "./components/auth/Login";
import Logout from "./components/auth/Logout";
import Home from "./components/home/Home";
import Entity from "./components/entity/Entity";
import Document from "./components/document/Document";
import EntityView from "./components/entity/EntityView";
import DocumentView from "./components/document/DocumentView";
import DocumentEdit from "./components/document/DocumentEdit";
import EntityEdit from "./components/entity/EntityEdit";

function App(props) {
  useEffect(() => {}, []);
  return (
    <div id="MainApp">
      <SideBar pageWrapId={"page-wrap"} outerContainerId={"MainApp"} />
      <div id="page-wrap">
        <TopNavigation />
        <div className="container align-right">
          <React.StrictMode>
            <Switch>
              <PublicRoute path="/login" component={Login} />

              <PrivateRoute path="/entity/:uuid/edit" component={EntityEdit} />

              <PrivateRoute path="/entity/:uuid" component={EntityView} />

              <PrivateRoute
                path="/document/:uuid/edit"
                component={DocumentEdit}
              />

              <PrivateRoute path="/document/:uuid" component={DocumentView} />

              <PrivateRoute path="/entity" component={Entity} />

              <PrivateRoute path="/document" component={Document} />

              <PrivateRoute path="/logout" component={Logout} />

              <PrivateRoute path="/" component={Home} />
            </Switch>
          </React.StrictMode>
        </div>
      </div>
    </div>
  );
}

export default App;
