import axios from "axios";

axios.defaults.withCredentials = true;
const Axios = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BACKEND_BASE_API_URL,
  headers: {
    // "API-TOKEN": "stQ9eGDx67cNSn8HWTcsxQDYgbemmw9U",
  },
});

// Logout if Auth Token is invalid
Axios.interceptors.response.use(
  (response) => {
    if (
      response.statusText === "OK" &&
      response.data.status === "false" &&
      [1000, 1001].includes(response.data.data.code)
    ) {
      window.localStorage.removeItem("app_data");
      window.location.reload();
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

export default {
  postDataWithFile: function (url, data) {
    let headers = {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    };
    return Axios.post(url, data, { headers: headers });
  },

  postData: function (url, data) {
    let headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    return Axios.post(url, data, { withCredentials: true, headers: headers });
  },

  getData: function (url, data) {
    let headers = {
      Accept: "application/json",
    };
    return Axios.get(url, {
      withCredentials: true,
      params: data,
      headers: headers,
    });
  },

  getFile: function (url) {
    let headers = {
      Accept: "application/json",
    };
    return axios.get(url, {
      headers: headers,
      responseType: "blob", // important
    });
  },
};
