import React, { useCallback, useEffect, useState } from "react";
import Loader from "../common/Loader";
import TableData from "../common/TableData";
import APIservices from "../../services/APIservices";
import { useHistory } from "react-router";
import dateFormat from "dateformat";

export default function Document(props) {
  const history = useHistory();

  let viewFile = async (row, operation) => {
    let url =
      typeof operation.link === "function"
        ? operation.link(row)
        : row[operation.link];
    window.open(url, "_blank");
  };

  let viewDocument = (row, operation) => {
    let url = "/document/" + row.uuid;
    history.push(url);
  };

  let editDocument = (row, operation) => {
    let url = "/document/" + row.uuid + "/edit";
    history.push(url);
  };

  const [state, setState] = useState({
    isLoading: true,
    filters: {
      page: {
        size: 10,
        current: 1,
      },
    },
    tableData: [],
    columns: {
      fields: [
        {
          dataField: "uuid",
          text: "UUID",
          hidden: true,
        },
        {
          dataField: "name",
          text: "Name",
        },
        {
          dataField: function (row) {
            return row.valid_from
              ? dateFormat(row.valid_from, "dd-mm-yyyy")
              : "";
          },
          text: "Valid From",
        },
        {
          dataField: function (row) {
            return row.valid_to ? dateFormat(row.valid_to, "dd-mm-yyyy") : "";
          },
          text: "Valid To",
        },
        {
          dataField: function (row) {
            return row.is_lifetime ? "Yes" : "N/A";
          },
          text: "Is Lifetime",
        },
        {
          dataField: function (row) {
            return row.type.name;
          },
          text: "Document Type",
        },
        {
          dataField: function (row) {
            return row.provider.name;
          },
          text: "Provider",
        },
      ],
      operation: [
        {
          icon: "fa-download",
          text: "File",
          link: (row) => {
            return row.file.file_url;
          },
          onClick: viewFile,
        },
        {
          icon: "fa-eye",
          text: "View",
          onClick: viewDocument,
        },
        {
          icon: "fa-pencil",
          text: "Edit",
          onClick: editDocument,
        },
      ],
    },
  });

  let alterState = useCallback(
    (key, value) => {
      state[key] = value.constructor === String ? value.trim() : value;
      setState({ ...state });
    },
    [state, setState]
  );

  useEffect(() => {
    APIservices.getDocuments(state.filters).then((response) => {
      if (response.data.data.error === undefined) {
        alterState("tableData", response.data.data);
        alterState("isLoading", false);
      } else {
        // console.log(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isLoading) {
    return <Loader />;
  } else {
    return (
      <div>
        <h1>Document</h1>
        <TableData headers={state.columns} rows={state.tableData} />
      </div>
    );
  }
}
