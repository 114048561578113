import React, { useEffect, useState, createContext } from "react";

//Creating Context
const AppContext = createContext();

//Exporting Provider
export function AppProvider(props) {
  const [state, setState] = useState({
    isAuthenticated: false,
    token: null,
    user: null,
  });

  useEffect(() => {
    let data = JSON.parse(window.localStorage.getItem("app_data"));
    if (data) {
      setState(data);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem("app_data", JSON.stringify(state));
  });

  return (
    <AppContext.Provider value={{ context: state, updateContext: setState }}>
      {props.children}
    </AppContext.Provider>
  );
}

export default AppContext;
