import API from "./API";

class APIservices {
  getDocumentTypes = async () => {
    return await API.getData("/document-types");
  };

  getProviders = async () => {
    return await API.getData("/providers");
  };

  getEntityTypes = async () => {
    return await API.getData("/entity-types");
  };

  getDocuments = async () => {
    return await API.getData("/documents");
  };

  submitDocument = async (formData) => {
    return this.submitDataWithFile(formData, "/documents");
  };

  submitEntity = async (formData) => {
    return this.submitDataWithFile(formData, "/entities");
  };

  transformDataToForm = (formData) => {
    var form_data = new FormData();
    for (var key in formData) {
      //submit as array
      if (["documents"].includes(key)) {
        for (var subkey in formData[key]) {
          form_data.append(key + "[" + subkey + "]", formData[key][subkey]);
        }
      } else {
        form_data.append(key, formData[key]);
      }
    }
    return form_data;
  };

  submitDataWithFile = async (formData, url) => {
    let form_data = this.transformDataToForm(formData);
    return API.postDataWithFile(url, form_data);
  };

  login = async (formData) => {
    return await API.postData("/login", formData);
  };

  logout = async () => {
    return await API.postData("/logout", {});
  };

  getEntities = async () => {
    return await API.getData("/entities");
  };

  getEntity = async (uuid) => {
    return await API.getData("/entities/" + uuid);
  };

  getDocument = async (uuid) => {
    return await API.getData("/documents/" + uuid);
  };

  getEntityDocuments = async (name) => {
    if (name.length === 0) {
      return await API.getData("/entity-documents");
    } else {
      return await API.getData("/entity-documents", { name: name });
    }
  };

  getNotificationTypes = async () => {
    return await API.getData("/notification-types");
  };

  getDurations = async () => {
    return await API.getData("/durations");
  };

  submitNotificationUser = async (formData) => {
    return API.postData("/user-notifications", formData);
  };

  // downloadFile = async (url) => {
  //     return await API.getFile(url);
  // }
}

export default new APIservices();
