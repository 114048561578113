import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import AppContext from "./AppProvider";

export function PrivateRoute({ component: Component, ...rest }) {
  const { context } = useContext(AppContext);
  return (
    <Route
      exact={true}
      {...rest}
      render={(props) =>
        context.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export function PublicRoute({ component: Component, ...rest }) {
  return (
    <Route
      exact={true}
      {...rest}
      render={(props) => <Component {...props} />}
    />
  );
}
