import React, { useContext, useState } from "react";
import LoginForm from "./LoginForm";
import AlertInfo from "../common/AlertInfo";
import AppContext from "../../services/AppProvider";
import { Redirect } from "react-router";

function Login(props) {
  const { context } = useContext(AppContext);
  const [state, setState] = useState({
    errors: {
      username: "",
      password: "",
    },
    isError: false,
  });

  let alterState = (key, value) => {
    state[key] = value.constructor === String ? value.trim() : value;
    setState({ ...state });
  };

  if (context.isAuthenticated) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="row">
        <div className="col">
          <LoginForm alterState={alterState} errors={state.errors} />
          <br />
          <AlertInfo
            isError={state.isError}
            errors={state.errors}
            alterState={alterState}
          />
        </div>
      </div>
    );
  }
}

export default Login;
