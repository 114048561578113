import React, { useEffect, useState } from "react";
import { Button, Form, Image, Modal } from "react-bootstrap";
import Loader from "../common/Loader";
import APIservices from "../../services/APIservices";
import defaultPreview from "../../assets/noimage.jpg";
import AlertPopup from "../common/AlertPopup";

export default function AddEntityModel(props) {
  const [state, setState] = useState({
    formData: {
      name: "",
      image: "",
      entity_type: "",
      documents: [],
    },
    entity_types: [],
    documents: [],
    preview: null,
    isLoading: false,
    showAlert: {
      show: false,
      isError: false,
      message: "",
    },
  });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let close = (e) => {
    closeModel();
  };

  let closeModel = () => {
    alterState({
      preview: null,
      isLoading: false,
      formData: {
        name: "",
        image: "",
        entity_type: "",
        documents: [],
      },
      showAlert: {
        show: false,
        isError: false,
        message: "",
      },
    });
    props.alterState({
      showAddEntityModel: false,
      refreshEntityDocuments: true,
    });
  };

  let alterAlertState = (key, value) => {
    let showAlert = state.showAlert;
    showAlert[key] = value;
    alterState("showAlert", showAlert);
  };

  let onFileChange = (e) => {
    let formData = state.formData;
    if (e.target.nextElementSibling != null) {
      formData["image"] = e.target.files[0];
      alterState("formData", formData);
    }

    if (formData["image"].type.split("/")[0] === "image") {
      //Renter for image/* Type
      const reader = new FileReader();
      reader.readAsDataURL(formData["image"]);
      reader.onloadend = (e) => {
        alterState("preview", reader.result);
      };
    } else {
      // Render For other */* Type
      alterState("preview", defaultPreview);
    }
  };

  let alterState = (key, value) => {
    let st = state;
    if (typeof key === "object") {
      st = { ...state, ...key };
    } else if (
      value === null ||
      value === undefined ||
      typeof value === "object"
    ) {
      st[key] = value;
    } else {
      st[key] = value.constructor === String ? value.trim() : value;
    }
    setState({ ...st });
  };

  let handleUserInput = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = state.formData;
    formData[name] = value;
    alterState("formData", formData);
    // console.log(state.formData);
  };

  let handleSelectMultiUserInput = (e) => {
    e.preventDefault();
    const { name } = e.target;
    let formData = state.formData;
    formData[name] = Array.from(e.target.selectedOptions, (item) => item.value);
    alterState("formData", formData);
    // console.log(state.formData);
  };

  let submitForm = async (e) => {
    alterState("isLoading", true);
    e.preventDefault();
    e.stopPropagation();
    APIservices.submitEntity(state.formData).then((response) => {
      let showAlert = state.showAlert;
      showAlert["show"] = response.data.status;
      if (response.data.status === true) {
        showAlert["isError"] = false;
        alterState("isLoading", false);
      } else {
        showAlert["isError"] = true;
        showAlert["message"] =
          response.data.data.message + " (" + response.data.data.code + ")";
      }
      alterState("showAlert", showAlert);
    });
  };

  let initData = (e) => {
    alterState("isLoading", true);
    const promiseArray = [
      APIservices.getEntityTypes(),
      APIservices.getDocuments(),
    ];
    Promise.allSettled(promiseArray)
      .then((data) => {
        const entityTypes = data[0].value;
        const documents = data[1].value;
        alterState({
          entity_types:
            entityTypes.data.status === true ? entityTypes.data.data : [],
          documents: documents.data.status === true ? documents.data.data : [],
          isLoading: false,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      <AlertPopup
        onConfirm={close}
        alterAlertState={alterAlertState}
        show={state.showAlert.show}
        isError={state.showAlert.isError}
        message={state.showAlert.message}
      />
      <Modal
        onShow={initData}
        size="lg"
        show={props.show}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Add Entity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {state.isLoading ? (
            <Loader />
          ) : (
            <div>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Entity Name"
                  value={state.formData.name}
                  onChange={handleUserInput}
                />
              </Form.Group>

              <Form.Group controlId="entity_type">
                <Form.Label>Entity Type</Form.Label>
                <Form.Control
                  name="entity_type"
                  as="select"
                  value={
                    state.formData.entity_type.length === 0
                      ? "Select Entity Type"
                      : state.formData.entity_type
                  }
                  onChange={handleUserInput}
                >
                  <option>Select</option>
                  {state.entity_types.map((entity_type) => {
                    return (
                      <option key={entity_type.uuid} value={entity_type.uuid}>
                        {entity_type.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="documents">
                <Form.Label>Document</Form.Label>
                <Form.Control
                  multiple={true}
                  name="documents"
                  as="select"
                  value={state.formData.documents}
                  onChange={handleSelectMultiUserInput}
                >
                  <option>Select</option>
                  {state.documents.map((document) => {
                    return (
                      <option key={document.uuid} value={document.uuid}>
                        {document.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="image">
                <Form.Label>Image</Form.Label>
                <Form.File
                  accept="image/*"
                  id="image"
                  name="image"
                  label={
                    state.formData.image.constructor === String
                      ? "Upload Image"
                      : state.formData.image.name
                  }
                  custom
                  onChange={onFileChange}
                />
              </Form.Group>
              <Form.Group>
                <div id="filePreview" className="text-center">
                  {state.preview && (
                    <Image
                      className="img-fluid"
                      src={state.preview}
                      alt="preview"
                    />
                  )}
                </div>
              </Form.Group>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
          <Button onClick={submitForm} variant="primary">
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
