import React, { useContext } from "react";
import { scaleRotate as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import AppContext from "../../services/AppProvider";

export default function SideBar(props) {
  const { context } = useContext(AppContext);
  return (
    <Menu {...props}>
      {context.isAuthenticated && (
        <Link className="menu-item" to="/">
          Home
        </Link>
      )}
      {context.isAuthenticated && (
        <Link className="menu-item" to="/entity">
          Entity
        </Link>
      )}
      {context.isAuthenticated && (
        <Link className="menu-item" to="/document">
          Document
        </Link>
      )}
      {context.isAuthenticated && (
        <Link className="menu-item" to="/logout">
          Logout
        </Link>
      )}
      {!context.isAuthenticated && (
        <Link className="menu-item" to="/login">
          Login
        </Link>
      )}
    </Menu>
  );
}
