import Loader from "../common/Loader";
import React, { useEffect, useState } from "react";
import APIservices from "../../services/APIservices";
import { Card, CardDeck, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import dateFormat from "dateformat";
import {
  EmailIcon,
  EmailShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

export default function DocumentView(props) {
  const [state, setState] = useState({
    isLoading: true,
    data: {},
  });

  let alterState = (key, value) => {
    let st = state;
    if (typeof key === "object") {
      st = { ...state, ...key };
    } else if (
      value === null ||
      value === undefined ||
      typeof value === "object"
    ) {
      st[key] = value;
    } else {
      st[key] = value.constructor === String ? value.trim() : value;
    }
    setState({ ...st });
  };

  useEffect(() => {
    APIservices.getDocument(props.match.params.uuid).then((response) => {
      // console.log(response.data);
      if (response.data.status === true) {
        alterState({ data: response.data.data, isLoading: false });
      } else {
        alert(
          "Error : " +
            response.data.data.message +
            " (" +
            response.data.data.code +
            ")"
        );
        // console.log(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isLoading) {
    return <Loader />;
  } else {
    return (
      <div className="view-document-details">
        <div className="h1 document-margin-1">
          Document &nbsp;
          <small>{state.data.name}</small>
        </div>
        <DocumentInfo data={state.data} />
        <RelatedEntities entities={state.data.entities} />
      </div>
    );
  }
}

let DocumentInfo = function (props) {
  return (
    <Row>
      <Col md={8}>
        <img
          className="img-fluid"
          src={props.data.file.preview}
          alt={props.data.file.file_info.name}
        />
      </Col>
      <Col md={4}>
        <div className="h3 document-margin-2">{props.data.name}</div>
        <p>
          <span className="font-italic">Provider</span> :{" "}
          <span className="font-weight-light">{props.data.provider.name}</span>
          <br />
          <span className="font-italic">Validity</span> :{" "}
          <span className="font-weight-light">
            {dateFormat(props.data.valid_from, "dd-mm-yyyy")} -{" "}
            {props.data.is_lifetime
              ? "Lifetime"
              : dateFormat(props.data.valid_to, "dd-mm-yyyy")}
          </span>
          <br />
          <span className="font-italic">Is Lifetime</span> :{" "}
          <span className="font-weight-light">
            {props.data.is_lifetime ? "Yes" : "N/A"}
          </span>
          <br />
          <span className="font-italic">Type</span> :{" "}
          <span className="font-weight-light">{props.data.type.name}</span>
          <br />
        </p>
        <p>
          <a
            rel="noopener noreferrer"
            className="button"
            href={props.data.file.file_url}
            target="_blank"
          >
            Download
          </a>
          &nbsp; &nbsp;
          <a
            rel="noopener noreferrer"
            className="button"
            href={props.data.file.file_view_url}
            target="_blank"
          >
            View
          </a>
        </p>
        <p>
          <WhatsappShareButton
            title={props.data.name}
            url={props.data.file.file_url}
          >
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>

          <EmailShareButton
            title={props.data.name}
            url={props.data.file.file_url}
          >
            <EmailIcon size={32} round={true} />
          </EmailShareButton>
        </p>
        <EntitiesAttached entities={props.data.entities} />
      </Col>
    </Row>
  );
};

let EntitiesAttached = function (props) {
  return (
    <div>
      <div className="h3 entity-margin-2">Entity Attached</div>
      <ul>
        {props.entities.map((entity, subIndex) => {
          return <li key={entity.uuid}> {entity.name} </li>;
        })}
      </ul>
    </div>
  );
};

let RelatedEntities = function (props) {
  return (
    <div>
      <div className="h4 document-margin-1">Related Entities</div>

      <Row>
        <CardDeck>
          {props.entities.map((entity, subIndex) => {
            return <EntityCard key={entity.uuid} entity={entity} />;
          })}
        </CardDeck>
      </Row>
    </div>
  );
};

let EntityCard = function (props) {
  const history = useHistory();

  let viewEntity = (event, uuid) => {
    let url = "/entity/" + uuid;
    history.push(url);
  };

  return (
    <Card
      onClick={(event) => viewEntity(event, props.entity.uuid)}
      border="light"
      style={{ width: "18rem" }}
    >
      <Card.Header>{props.entity.type.name}</Card.Header>
      <Card.Body>
        <Card.Title>
          <div className="card-img-block">
            <Card.Img src={props.entity.image.thumbnail} />
          </div>
        </Card.Title>
        <Card.Text>{props.entity.name}</Card.Text>
      </Card.Body>
    </Card>
  );
};
