import React from "react";

export default function ErrorPage(props) {
  return (
    <div className="page-wrap d-flex flex-row align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <span className="display-1 d-block">{props.code}</span>
            <div className="mb-4 lead">{props.message}</div>
            {/*<a href="https://www.totoprayogo.com/#" className="btn btn-link">Back to Home</a>*/}
          </div>
        </div>
      </div>
    </div>
  );
}
