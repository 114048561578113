import React, { useEffect, useState } from "react";
import Loader from "../common/Loader";
import APIservices from "../../services/APIservices";
import { Button, Col, Form, Image } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import defaultPreview from "../../assets/noimage.jpg";
import PDFObject from "pdfobject/pdfobject.min";
import dateFormat from "dateformat";
import AlertPopup from "../common/AlertPopup";
import { useHistory } from "react-router";

export default function DocumentEdit(props) {
  const history = useHistory();

  const [state, setState] = useState({
    formData: {
      uuid: props.match.params.uuid,
      name: "",
      valid_from: "",
      valid_to: "",
      document_type: "",
      is_lifetime: "",
      provider: "",
      document_file: "",
      document_file_uuid: "",
    },
    file: {
      link: "",
      name: "",
      preview: "",
    },
    document_types: [],
    providers: [],
    preview: null,
    isLoading: true,
    showAlert: {
      show: false,
      isError: false,
      message: "",
    },
  });

  let alterState = (key, value) => {
    let st = state;
    if (typeof key === "object") {
      st = { ...state, ...key };
    } else if (
      value === null ||
      value === undefined ||
      typeof value === "object"
    ) {
      st[key] = value;
    } else {
      st[key] = value.constructor === String ? value.trim() : value;
    }
    setState({ ...st });
  };

  let onFileChange = (e) => {
    let formData = state.formData;
    if (e.target.nextElementSibling != null) {
      formData["document_file"] = e.target.files[0];
      alterState("formData", formData);
    }

    // remove PDFObject
    let filePreview = document.getElementById("filePreview");
    alterState("preview", null);
    if (document.getElementById("pdfFilePreview") !== null) {
      filePreview.removeChild(document.getElementById("pdfFilePreview"));
    }
    // Render for application/pdf
    if (formData["document_file"].type === "application/pdf") {
      let newDiv = document.createElement("div");
      newDiv.setAttribute("id", "pdfFilePreview");
      filePreview.appendChild(newDiv);
      let src = URL.createObjectURL(formData["document_file"]);
      PDFObject.embed(src, "#pdfFilePreview");
    } else if (formData["document_file"].type.split("/")[0] === "image") {
      //Renter for image/* Type
      const reader = new FileReader();
      reader.readAsDataURL(formData["document_file"]);
      reader.onloadend = (e) => {
        alterState("preview", reader.result);
      };
    } else {
      // Render For other */* Type
      alterState("preview", defaultPreview);
    }
  };

  let handleUserInput = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { name, value } = e.target;
    let formData = state.formData;
    formData[name] = value;
    alterState("formData", formData);
  };

  let handleCheckInput = (e) => {
    const { name, checked } = e.target;
    let formData = state.formData;
    formData[name] = +checked;
    console.log(formData);
    alterState("formData", formData);
  };

  let submitForm = async (e) => {
    alterState("isLoading", true);
    e.preventDefault();
    e.stopPropagation();
    APIservices.submitDocument(state.formData).then((response) => {
      let showAlert = state.showAlert;
      showAlert["show"] = response.data.status;
      if (response.data.status === true) {
        showAlert["isError"] = false;
        alterState("isLoading", false);
      } else {
        showAlert["isError"] = true;
        showAlert["message"] =
          response.data.data.message + " (" + response.data.data.code + ")";
      }
      alterState("showAlert", showAlert);
    });
  };

  useEffect(() => {
    alterState("isLoading", true);
    const promiseArray = [
      APIservices.getDocumentTypes(),
      APIservices.getProviders(),
      APIservices.getDocument(state.formData.uuid),
    ];
    Promise.allSettled(promiseArray)
      .then((data) => {
        const documentTypes = data[0].value;
        const providers = data[1].value;
        const document = data[2].value;
        alterState({
          document_types: documentTypes.data.data,
          providers: providers.data.data,
          formData: {
            uuid: document.data.data.uuid,
            name: document.data.data.name,
            valid_from: document.data.data.valid_from
              ? dateFormat(document.data.data.valid_from, "yyyy-mm-dd")
              : "",
            valid_to: document.data.data.valid_to
              ? dateFormat(document.data.data.valid_to, "yyyy-mm-dd")
              : "",
            document_type: document.data.data.type.uuid,
            is_lifetime: +document.data.data.is_lifetime,
            provider: document.data.data.provider.uuid,
            document_file: "",
            document_file_uuid: document.data.data.file.uuid,
          },
          file: {
            link: document.data.data.file.file_url,
            name: document.data.data.file.file_info.name,
            preview: document.data.data.file.preview,
          },
          isLoading: false,
        });
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let alterAlertState = (key, value) => {
    let showAlert = state.showAlert;
    showAlert[key] = value;
    alterState("showAlert", showAlert);
  };

  let close = (e) => {
    let url = "/document";
    history.push(url);
  };

  if (state.isLoading) {
    return <Loader />;
  } else {
    return (
      <div>
        <AlertPopup
          onConfirm={close}
          alterAlertState={alterAlertState}
          show={state.showAlert.show}
          isError={state.showAlert.isError}
          message={state.showAlert.message}
        />
        <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            name="name"
            type="text"
            placeholder="Document Name"
            value={state.formData.name}
            onChange={handleUserInput}
          />
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlId="valid_from">
            <Form.Label>Valid From</Form.Label>
            <Form.Control
              name="valid_from"
              type="date"
              placeholder="Document Valid From"
              value={state.formData.valid_from}
              onChange={handleUserInput}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="valid_to">
            <Form.Label>Valid To</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Checkbox
                  defaultChecked={state.formData.is_lifetime}
                  name="is_lifetime"
                  onClick={handleCheckInput}
                />
                <InputGroup.Text>LifeTime</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                disabled={state.formData.is_lifetime}
                name="valid_to"
                type="date"
                placeholder="Document Valid To"
                value={state.formData.valid_to}
                onChange={handleUserInput}
              />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="document_type">
            <Form.Label>Type</Form.Label>
            <Form.Control
              name="document_type"
              as="select"
              value={
                state.formData.document_type.length === 0
                  ? "Document Type"
                  : state.formData.document_type
              }
              onChange={handleUserInput}
            >
              <option>Select</option>
              {state.document_types.map((document_type) => {
                return (
                  <option key={document_type.uuid} value={document_type.uuid}>
                    {document_type.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="provider">
            <Form.Label>Provider</Form.Label>
            <Form.Control
              name="provider"
              as="select"
              value={
                state.formData.provider.length === 0
                  ? "Document Provider"
                  : state.formData.provider
              }
              onChange={handleUserInput}
            >
              <option>Select</option>
              {state.providers.map((provider) => {
                return (
                  <option key={provider.uuid} value={provider.uuid}>
                    {provider.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="document_type">
          <Form.Label>Current Document File</Form.Label>
          <div>
            <a href={state.file.link}>{state.file.name}</a>
          </div>
          <div className="text-center">
            <Image
              id="current-image"
              className="previewFile"
              src={state.file.preview}
              alt={state.file.name}
            />
          </div>
        </Form.Group>
        <Form.Group controlId="document_type">
          <Form.Label>New Document File</Form.Label>
          <Form.File
            id="document_file"
            name="document_file"
            label={
              state.formData.document_file.constructor === String
                ? "Document File"
                : state.formData.document_file.name
            }
            custom
            onChange={onFileChange}
          />
        </Form.Group>
        <Form.Group>
          <div id="filePreview" className="text-center">
            {state.preview && (
              <Image
                className="previewFile"
                src={state.preview}
                alt="preview"
              />
            )}
          </div>
        </Form.Group>
        <Button onClick={submitForm} variant="primary">
          Update
        </Button>
      </div>
    );
  }
}
