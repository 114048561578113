import React, { useContext, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import APIservices from "../../services/APIservices";
import ErrorPage from "../common/ErrorPage";
import Loader from "../common/Loader";
import AppContext from "../../services/AppProvider";

const emailRegx = RegExp(
  /^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\]+)\.([a-zA-Z.]{2,})$/
);

function LoginForm(props) {
  const { updateContext } = useContext(AppContext);
  const [state, setState] = useState({
    isLoading: false,
    formData: {
      email: "",
      password: "",
      remember_me: false,
    },
    error: {
      show: false,
      data: {
        code: "",
        message: "",
      },
    },
  });

  let alterState = (key, value) => {
    state[key] = value.constructor === String ? value.trim() : value;
    setState({ ...state });
  };

  let handleUserInput = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log({ name, value });
    let formData = state.formData;
    formData[name] = value;
    alterState("formData", formData);
  };

  let handleUserCheckBoxInput = (e) => {
    e.preventDefault();
    const { name, checked } = e.target;
    console.log({ name, checked });
    let formData = state.formData;
    formData[name] = !formData[name];
    alterState("formData", formData);
  };

  let inputValidation = () => {
    let isValid = true;

    let errors = props.errors;

    if (
      state.formData.email.length > 0 &&
      emailRegx.test(state.formData.email)
    ) {
      errors.email = "";
    } else {
      errors.email = "Invalid Email";
      isValid = false;
    }

    if (state.formData.password.length > 3) {
      errors.password = "";
    } else {
      errors.password = "Invalid Password";
      isValid = false;
    }

    props.alterState("errors", errors);
    props.alterState("isError", !isValid);
    return isValid;
  };

  let submitForm = async (e) => {
    alterState("isLoading", true);
    e.preventDefault();
    e.stopPropagation();
    let isValid = true;

    if (!inputValidation()) {
      alterState("isLoading", false);
      return false;
    } else {
      const response = await APIservices.login(state.formData);
      if (response.data.status === true) {
        let data = {
          token: response.data.meta.token,
          user: response.data.data,
          isAuthenticated: true,
        };
        updateContext(data);
        return;
      } else {
        let errors = props.errors;
        errors.name = response.data.data.message;
        props.alterState("errors", errors);
        isValid = false;
        alterState("isLoading", false);
      }
      props.alterState("isError", !isValid);
    }
  };

  if (state.error.show)
    return (
      <ErrorPage
        code={state.error.data.code}
        message={state.error.data.message}
      />
    );
  else if (state.isLoading) return <Loader />;
  else
    return (
      <div>
        <Card>
          <Card.Header>Login</Card.Header>
          <Card.Body>
            <Form>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Enter Email"
                  value={state.formData.email}
                  onChange={handleUserInput}
                />
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  spellCheck="false"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={state.formData.password}
                  onChange={handleUserInput}
                />
              </Form.Group>

              <Form.Group controlId="remember_me">
                <Form.Check
                  type="checkbox"
                  label="Remember Me"
                  name="remember_me"
                  checked={state.formData.remember_me}
                  onChange={handleUserCheckBoxInput}
                />
              </Form.Group>

              <Button variant="primary" type="submit" onClick={submitForm}>
                Login
              </Button>
            </Form>
          </Card.Body>
          <Card.Footer className="text-muted" />
        </Card>
      </div>
    );
}

export default LoginForm;
